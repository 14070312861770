
/**
 * @name: 分成管理-余额提现申请
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 分成管理-余额提现申请
 * @update: 2024-07-18 14:45
 */
import { Vue, Component } from "vue-property-decorator"
import {
  withDrawApplyPageApi,
  withDrawApplyControlApi,
  withDrawApplyDetailApi,
  withDrawApplyExportApi,
  withDrawApplySendApi
} from '@/apis/split/withdraw-apply'
import {
  IWithdrawApply,
  IWithdrawApplyParams
} from '@/apis/split/withdraw-apply/types'
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy, exportFile } from "@/utils/common"
import config from '@/config'

@Component({})
export default class splitWithdrawApply extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IWithdrawApply[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IWithdrawApplyParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    column: [
      {
        label: '',
        type: 'selection',
        width: 50,
        align: 'center',
        selectable: function (row: IWithdrawApply, index: number) {
          if (row.grantStatus === 1 && row.status === 2) {
            return true
          }
          return false
        }
      },
      {
        label: '申请单号',
        prop: 'id',
        width: 180,
        align: 'center'
      },
      {
        label: '申请单号',
        prop: 'applySn',
        search: true,
        hide: true
      },
      {
        label: '姓名',
        prop: 'nickName',
        align: 'center',
        width: 150,
        overHidden: true
      },
      {
        label: '手机号',
        prop: 'userPhone',
        align: 'center',
        width: 120
      },
      {
        label: '申请人',
        prop: 'applicant',
        hide: true,
        search: true,
        placeholder: '姓名/手机号'
      },
      {
        label: '提现金额(元)',
        prop: 'withdrawalPrice',
        align: 'center',
        width: 100
      },
      {
        label: '开户名',
        prop: 'accountName',
        align: 'center',
        search: true,
        width: 150
      },
      {
        label: '银行卡号',
        prop: 'bankCardNumber',
        align: 'center',
        search: true,
        width: 160
      },
      {
        label: '身份证号',
        prop: 'idCard',
        align: 'center',
        search: true,
        width: 150
      },
      {
        label: '身份证正面',
        prop: 'idCardFrontPic',
        align: 'center',
        type: 'image',
        imgPrefix: config.downloadUrl,
        width: 150
      },
      {
        label: '身份证反面',
        prop: 'idCardBackPic',
        align: 'center',
        type: 'image',
        imgPrefix: config.downloadUrl,
        width: 150
      },
      {
        label: '申请时间',
        prop: 'applyTime',
        align: 'center',
        type: 'daterange',
        search: true,
        width: 150
      },
      {
        label: '审核状态',
        prop: 'status',
        align: 'center',
        type: 'select',
        search: true,
        slot: true,
        dicData: [
          {
            label: '待审核',
            value: 1
          },
          {
            label: '已通过',
            value: 2
          },
          {
            label: '已拒绝',
            value: 3
          },
        ]
      },
      {
        label: '发放状态',
        prop: 'grantStatus',
        align: 'center',
        type: 'select',
        search: true,
        dicData: [
          {
            label: '待发放',
            value: 1
          },
          {
            label: '已发放',
            value: 2
          }
        ]
      }
    ]
  }

  applyDetailDialog = false

  applyDetailObj: Partial<IWithdrawApply> = {}

  selectRows: IWithdrawApply[] = []

  auditDialog = false

  selectId = ''

  radioForm: any = {
    audit: 1,
    remarks: ''
  }

  get ids () {
    return this.selectRows.map(item => item.id)
  }

  getList () {
    this.tableLoading = true

    const query: IWithdrawApplyParams = deepCopy(this.queryParam)
    if (query.applyTime && query.applyTime.length === 2) {
      query.applyTimeStart = query.applyTime[0]
      query.applyTimeEnd = query.applyTime[1]
    } else {
      query.applyTimeStart = ''
      query.applyTimeEnd = ''
    }
    delete query.applyTime

    withDrawApplyPageApi(query).then(e => {
      if (e && e.data) {
        this.tableLoading = false
        this.tableData = e.data.list
        this.tableTotal = e.data.total
      }
    })
  }

  getStatus (status: number) {
    if (status === 1) {
      return '待审核'
    }
    if (status === 2) {
      return '已通过'
    }
    if (status === 3) {
      return '已拒绝'
    }
  }

  exportExcel () {
    const query: IWithdrawApplyParams = deepCopy(this.queryParam)
    if (query.applyTime && query.applyTime.length === 2) {
      query.applyTimeStart = query.applyTime[0]
      query.applyTimeEnd = query.applyTime[1]
    } else {
      query.applyTimeStart = ''
      query.applyTimeEnd = ''
    }
    delete query.applyTime

    withDrawApplyExportApi(query).then(e => {
      if (e && e.data) {
        exportFile(e.data, '余额提现申请.xlsx')
        this.$message.success('操作成功!')
      }
    })
  }

  batchSend (arr?: string[]) {
    if (!arr) {
      if (!this.ids || !this.ids.length) {
        return this.$message.warning('请选择数据')
      }
    }
    this.$confirm('是否确认标记发放?', '系统提示', {}).then(() => {
      withDrawApplySendApi(arr || this.ids).then(e => {
        if (e && e.data) {
          this.$message.success('操作成功!')
          this.getList()
        }
      })
    }).catch(() => {

    })
  }

  openApplyDetail (row: IWithdrawApply) {
    withDrawApplyDetailApi(row.id).then(e => {
      if (e && e.data) {
        this.applyDetailObj = e.data
        this.applyDetailDialog = true
      }
    })
  }

  openAudit (row: IWithdrawApply) {
    this.selectId = row.id
    this.radioForm.audit = 2
    this.radioForm.remarks = ''
    this.auditDialog = true
  }

  auditEnter (done: Function, loading: Function) {
    // @ts-ignore
    this.$refs.remarkForm.validate(valid => {
      if (valid) {
        withDrawApplyControlApi({
          id: this.selectId,
          reason: this.radioForm.remarks,
          status: this.radioForm.audit
        }).then(e => {
          if (e) {
            this.$message.success('操作成功!')
            this.getList()
            done()
          }
        }).finally(() => {
          loading()
        })
      } else {
        loading()
      }
    })
  }

  auditClose () {
    this.radioForm.audit = 2
    this.radioForm.remarks = ''
    this.selectId = ''
  }

  created () {
    this.getList()
  }
}
