/**
 * @name: 分成管理-余额提现申请接口文件
 * @author: itmobai
 * @date: 2024-07-18 14:45
 * @description： 分成管理-余额提现申请接口文件
 * @update: 2024-07-18 14:45
 */
import api from '@/request/api'
import {IPageRes} from "@/apis/page";
import { IWithdrawApply, IWithdrawApplyParams } from './types';


export const withDrawApplyPageApi = (params: IWithdrawApplyParams) => api.get<IPageRes<IWithdrawApply[]>>('/admin/user/withdrawal/queryByPage', params)

export const withDrawApplyExportApi = (params: IWithdrawApplyParams) => api.get('/admin/user/withdrawal/withdrawalApplyExport', params, 'blob')

export const withDrawApplyDetailApi = (id: string) => api.get<IWithdrawApply>('/admin/user/withdrawal/detail/' + id)

export const withDrawApplyControlApi = (data: any) => api.postJ('/admin/user/withdrawal/approval', data)

export const withDrawApplySendApi = (ids: string[]) => api.postJ('/admin/user/withdrawal/batchGrant', {ids})
